<template>
  <div class="exchange-wrap">
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="warp">
        <div class="banner">
          <img src="../../../assets/images/liyu.jpg">
        </div>

        <div class="coupon-list-wrap">
          <div class="left">
            <div class="tab-list">
              <div class="title">
                兑换记录
              </div>
              <div class="integral-wrap">
                <span>当前邻里值:</span>
                <span class="integral">{{integral}}</span>
              </div>
            </div>
            <div class="coupon-list" v-if="historyList.length > 0">

              <div class="reward-item" v-for="(item,index) in historyList" :key="index">
                <div class="image">
                  <img :src="item.order_products[0].original_image" />
                </div>
                <div class="name-wrap">
                  <div class="name">
                    <div>{{ item.order_products[0].name }}</div>
                    <div class="status" :class="item.order_status_code">{{ item.order_status_code == "paid" ? "待发放" : item.order_status_code == "shipped" ? "已发放" : ""}}</div>
                  </div>
                  <div class="time">兑换时间:{{ item.date_added }}</div>
                </div>
                <div class="number">{{ item.points }}</div>
              </div>

            </div>

            <div class="coupon-list not" v-else>
              <div class="tip">暂无可兑换商品</div>
            </div>

            <el-pagination layout="prev, pager, next" :total="total_exchange" :page-size="per_page" :current-page="page" @current-change="changePage" :hide-on-single-page="true" class="pagination">
            </el-pagination>

          </div>
          <div class="right">
            <div class="ad-wrap">
              <div class="title">为您推荐</div>
              <div class="img-wrap">
                <img src="../../../assets/images/ad1.jpg">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
// import utils from '@/common/utils'

export default {
  data () {
    return {
      integral:0,
      historyList: [],
      page: 1,
      per_page: 10,
      total_exchange: 0,
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    page: function (e) {
      if (e) {
        this.getGoodsList();
      }
    }
  },
  created () {
    this.getIntegral();
    this.getGoodsList();
  },
  methods: {
    getIntegral: function () {
      let that = this;

      that.$axios.get(that.ocApi + 'account/reward/total', {})
        .then(res => {
          if (res.data.status == 1) {
              that.integral= res.data.data.reward_total
          }
        })
    },
    getGoodsList: function () {
      let that = this;

      let page = that.page;
      let per_page = that.per_page;

      that.$axios.get(that.ocApi + 'points/orders', {
        params: {
          per_page,
          page
        }
      })
        .then(res => {
          that.total_exchange = res.data.total
          that.historyList = res.data.data
        })
    },
    changePage (page) {
      this.page = page
    }
  },
}
</script>

<style lang="scss" scoped>
.exchange-wrap {
  .el-dropdown-link {
    cursor: pointer;
  }
  .pagination {
    text-align: center;
    padding: 20px 0;
    &.el-pagination {
      :deep(button:disabled) {
        background-color: #f7f7f7;
      }
      :deep(.btn-next),
      :deep(.btn-prev) {
        background-color: #f7f7f7;
      }
      :deep(.el-pager li) {
        background-color: #f7f7f7;
      }
    }
  }

  .content {
    background-color: #f7f7f7;
    min-height: calc(100vh - 226px);
    .warp {
      width: 1200px;
      margin: auto;
      padding-top: 114px;
      .banner {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .coupon-list-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        padding-bottom: 60px;
        .left {
          flex: 1;
          .tab-list {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            padding: 4px 0;
            .title {
              color: #434343;
            }
            .integral-wrap {
              color: #999999;
              .integral {
                color: #00d596;
                font-weight: 600;
              }
            }
          }
          .coupon-list {
            &.not {
              text-align: center;
              .tip {
                padding: 60px 0;
              }
            }
            .reward-item {
              display: flex;
              padding: 10px 48px 10px 19px;
              background-color: #fff;
              border-radius: 4px;
              margin-top: 16px;
              align-items: center;
              .image {
                width: 133px;
                > img {
                  width: 100%;
                }
              }
              .name-wrap {
                margin-left: 34px;
                flex: 1;
                .name {
                  font-size: 16px;
                  color: #434343;
                  display: flex;
                  .status {
                    font-size: 11px;
                    line-height: 11px;
                    color: #fff;
                    border-radius: 50px;
                    margin-left: 7px;
                    width: 50px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    &.paid {
                      background-color: #ec6941;
                    }
                    &.shipped {
                      background-color: #9fa3a9;
                    }
                  }
                }
                .time {
                  margin-top: 16px;
                  font-size: 12px;
                  color: #999999;
                }
              }
              .number {
                font-size: 16px;
                color: #434343;
                font-weight: 600;
              }
            }
          }
        }
        .right {
          width: 320px;
          margin-left: 40px;

          .ad-wrap {
            width: 100%;
            border-radius: 4px;
            padding: 16px 20px 20px 20px;
            box-sizing: border-box;
            background-color: #fff;
            margin-top: 46px;
            .title {
              font-size: 16px;
              color: #000000;
              font-weight: 400;
            }
            .img-wrap {
              width: 100%;
              margin-top: 14px;
              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>